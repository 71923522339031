import React, { Component } from 'react';
import { graphql, navigate, Link } from 'gatsby';
import styled from 'react-emotion';
import CurrencyFormat from 'react-currency-format';
import dotenv from 'dotenv';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import ReactPixel from 'react-facebook-pixel';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
// import Slider from "react-slick";
// import ProductCard from '../../../components/product-card';
import Layout from '../../../components/layout';
import Container from '../../../components/container';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

dotenv.config();
const isSSR = typeof window === 'undefined';
if (!isSSR) {
  registerLocale('id', id);
}
// Query result goes to this.props.data
export const query = graphql`
  query {
    settings{
      data{
        blacklist
        simblacklist
      }
    }
  }
`
const ButtonRow = styled.div`
  ${tw`block md:flex mt-4`}
  margin-left: -4px;
  margin-right: -4px;
`
const BackButton = styled.a`
  ${tw`text-white rounded py-2 border-none cursor-pointer block w-full text-center bg-wr-red`}
  text-decoration: none;
  border: 1px solid #E92028;
`

const ProductPhotos = styled.div
  `${tw`block w-full align-top md:inline-block md:w-1/3`}`

const ProductDetails = styled.div
  `${tw`block w-full align-top md:pl-3 md:inline-block md:w-2/3`}`

const Block = styled.div
  `${tw`p-7`}`

const PrimaryImage = styled.img
  `${tw`block w-full border-solid border-black border-1`}`

const Carousel = styled.div`
  ${tw`w-full flex items-stretch mb-6`}
`

const CarouselItem = styled.div`
  ${tw`inline-block w-1/3 border-solid border-black flex flex-col justify-center`}
  margin: 0 5px;
`

const CarouselImage = styled.img`
  ${tw`m-0`}
  min-height:100%;
  min-width: 100%;
  object-fit: cover;
`

const Name = styled.h1
  `${tw`text-left`}`

const Price = styled.div
  `${tw`text-red-dark font-semibold text-base`}`

const ButtonContainer = styled.div`
${tw`w-full mt-2 md:m-0`};
  padding: 0 4px;
`
class NetPack extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: '',
    description: '',meta_description:'',
    discount_price: 0,
    frontImage: '',
    images: [],
    price: 0,
    duration: 0,
    thumbs: [],
    title: "",
    category: 'netpack',
    promo:0
  }

  componentDidMount() {
    ReactPixel.init(process.env.GATSBY_PIXEL_ID)
    ReactPixel.pageView()
    // const referralCode = this.props.location ? queryString.parse(this.props.location.search).ref_id : '';
    // if (referralCode) {
    //   this.props.changeReferralCode(referralCode);
    // }
    // console.log(this.props.pageContext)
    
    this.setState({
      id: this.props.pageContext.productId,
      description: this.props.pageContext.description,
      meta_description: this.props.pageContext.meta_description,
      discount_price: this.props.pageContext.discount_price,
      images: this.props.pageContext.images,
      frontImage: this.props.pageContext.images[0],
      price: this.props.pageContext.price,
      duration: this.props.pageContext.duration
    });
    console.log({a:this.props.location.state})
  }

  changeFrontImage = image => {
    this.setState({
      frontImage: image
    });
  }
  backHand = () => {
    navigate(-1,{state:{...this.props.location.state}});
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.props.pageContext.title + ' | Wifi Republic'}</title>
          <link rel="alternate" href={this.props.location.href} hrefLang="id" />
          <link rel="canonical" href={this.props.location.href} />
          <meta name="title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="description" content={ this.props.pageContext.meta_description} />
          <meta name="thumbnail" content={this.props.pageContext.thumb} />
          <meta name="og:title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="og:type" content="website" />
          <meta name="og:description" content={ this.props.pageContext.meta_description} />
          <meta name="og:image" content={this.props.pageContext.thumb} />
          <meta name="og:url" content={this.props.location.href} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="twitter:description" content={ this.props.pageContext.meta_description} />
          <meta name="twitter:image" content={this.props.pageContext.thumb} />
          <meta name="twitter:url" content={this.props.location.href} />
          <meta name="twitter:site" content="@wifirepublic" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": this.props.pageContext.title,
              "image": this.props.pageContext.thumb,
              "description": this.props.pageContext.meta_description,
              "brand": "Wifirepublic",
              "offers": {
                "@type": "Offer",
                "url": this.props.location.href,
                "priceCurrency": "IDR",
                "price": this.props.pageContext.discount_price,
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            })}
          </script>
        </Helmet>
        <Container>
          <ProductPhotos>
            <Block>
              <PrimaryImage src={this.state.frontImage.url} alt={this.state.frontImage.alt}></PrimaryImage>
              <Carousel>
                {
                  this.state.images.map((image,idx) => (
                    <CarouselItem key={'carr_'+idx}>
                      <CarouselImage src={image.url} onClick={() => this.changeFrontImage(image)} alt={image.alt}/>
                    </CarouselItem>
                  ))
                }
              </Carousel>
            </Block>
          </ProductPhotos>
          <ProductDetails>
            <Name>{this.props.pageContext.title}</Name>
            <Price>
              {
                (this.state.price !== this.state.discount_price)
                ? (
                  <CurrencyFormat
                    value={this.state.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                    style={{ textDecoration: 'line-through', marginRight: '10px' }}
                  />
                ) : null
              }
              <CurrencyFormat
                value={this.state.discount_price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'Rp '}
              />
            </Price>
            <div>
              <SanitizedHTML
                html={
                  this.props.pageContext.pgcontent
                }
              />
            </div>
            <ButtonRow>
                <ButtonContainer>
                  <BackButton onClick={this.backHand}>Kembali..</BackButton>
                </ButtonContainer>
            </ButtonRow>
          </ProductDetails>
        </Container>
      </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
    shoppingCart: state.shoppingCart
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NetPack);